
import Vue from "vue";
import moment from "moment";

export default Vue.extend({
  name: "DisputeStatus",

  props: {
    user: {
      type: Object,
      required: true,
    }
  },

  data: () => ({
    disputeStatus: {
      "total": {
        "equifax": "",
        "experian": "",
        "transunion": "",
        "items" : ""
      },
      "positive": {
        "equifax": "",
        "experian": "",
        "transunion": "",
        "items" : ""
      },
      "negative": {
        "equifax": "",
        "experian": "",
        "transunion": "",
        "items" : ""
      },
      "in-dispute": {
        "equifax": "",
        "experian": "",
        "transunion": "",
        "items" : ""
      },
      "updated": {
        "equifax": "",
        "experian": "",
        "transunion": "",
        "items" : ""
      },
      "verified": {
        "equifax": "",
        "experian": "",
        "transunion": "",
        "items" : ""
      },
      "repaired": {
        "equifax": "",
        "experian": "",
        "transunion": "",
        "items" : ""
      },
      "deleted": {
        "equifax": "",
        "experian": "",
        "transunion": "",
        "items" : ""
      }
    } as any,
    editItems: null as any,
    loading: true as boolean,
    labels: {
      deleted: "Deleted",
      "in-dispute": "In Dispute",
      negative: "Negative",
      positive: "Positive",
      repaired: "Repaired",
      updated: "Updated",
      verified: "Verified",
      total: "Total"
    },
    editMode: false as boolean,
    dialog: {
      status: false,
      selectedKey: false as any,
      items: null as any
    },
    disputeTotalItems: 0,
    hasDisputeStatus: false,
    userIsGuest: false,
  }),
  async created() {
    await this.getDisputeStatus();
    this.userHasDisputeStatus();

  },
  mounted() {
    this.currentUserRole();
  },
  watch: {
    "dialog.items"(val, oldVal){
      console.log(val, val.length)
      if(typeof val == 'string')
      {
        this.dialog.items = val.split("\n");
      }
    }
  },
  methods: {
    //Get the dispute status info
    async getDisputeStatus(): Promise <void> {
      try{
        this.loading = true;
        let res = await this.$API.user().getDisputeStatus(this.user.id);
        if(res.success)
        {
          this.disputeStatus = res.data;
        }
        this.loading = false;
      }catch (e)
      {
        await this.$store.dispatch('alert/showError', e.message);
        this.loading = false;
      }
    },

    // Make the form editable
    makeEditable(){
      this.editMode = true;
    },
    //Store the dispute status
    async saveDisputedStatus(): Promise <any>{
      try{
        this.editMode = false;
        let res = await this.$API.user().storeDisputeStatus(this.user.id, this.disputeStatus);
        if(res.success)
        {
          await this.$store.dispatch('alert/showSuccess', res.message);
        }else{
          await this.$store.dispatch('alert/showError', "Something went wrong");
        }
      }catch (e)
      {
        await this.$store.dispatch('alert/showError', e.message);
      }
    },

    // Open Dialog and set current additional details
    openDialog(key: string | number){
      this.dialog.status = true;
      this.dialog.selectedKey = key;
      this.dialog.items = this.disputeStatus[key].items;
    },

    cancelChanges(){
      this.editMode = !this.editMode;
      this.getDisputeStatus()
    },

    itemsCount(key: string | number){
      let items = this.disputeStatus[key].items;
      if(items != null && items.length != 0){
        let array = items.split('\n');
        return array.length;
      } else {
        return 0;
      }
    },

    totalItemsCount($name: any){
      let sum = 0;
      const disputeItems = this.disputeStatus;
      for (const item in disputeItems) {
        if(disputeItems[item] !== 'total'){
          let type = disputeItems[item][$name];
          if(type){
            sum += parseInt(type);
          }
        }
      }
      return sum == 0 ? null : sum;
    },
    userHasDisputeStatus(){
      this.hasDisputeStatus = true;
      const disputeItems = this.disputeStatus;
      for (const item in disputeItems) {
        for (const type in disputeItems[item]) {
          if(disputeItems[item][type]){
            this.hasDisputeStatus = false;
            break;
          }
        }
      }
    },
    currentUserRole(){
      const currentUser = this.$store.getters["authentication/credentials"];
      if(currentUser){
        if(currentUser.user.role === 'guest'){
          this.userIsGuest = true
        }
      }
    },
  }


});
