
import Vue from "vue";
import store from "@/store";
import {is} from "@babel/types";

export default Vue.extend({
    name: "ProfileContact",

    props: {
        user: {
            type: Object,
            required: true
        },
      unreadMessagesCount: {
          type: Number,
          required: true
      }
    },
  data: () => ({
    isManager: false as boolean
  }),
  mounted() {
    this.currentUserIsManager();
  },
  methods: {
    is,
      currentUserIsManager(){
        const { user } = store.getters["authentication/credentials"];
        console.log(user.role);
        if (user.role === "manager" || user.role === "main_manager") {
          this.isManager = true;
        }
      },
      log(message: any){
        console.log(message);
      }
  }
});
