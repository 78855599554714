
import Vue from "vue";
import moment from "moment";

export default Vue.extend({
    name: "Recommendations",

    props: {
        user: {
            type: Object,
            required: true,
        }
    },

    data: () => ({
        recommendations: false as any,
        dialog: {
            status: false,
            action: "" as string
        },
        recommendationText: "" as string,
        recommendationId: null as any,
        canSubmit: false,
        loading: false,
    }),

    async created() {
        await this.getRecommendations()
    },

    methods: {

        /**
         * Get the recommendations
         */
        async getRecommendations(): Promise <void> {
            try{
                let res = await this.$API.user().getRecommendations(this.user.id);
                if(res.success)
                {
                    this.recommendations = res.data;
                }else{
                    await this.$store.dispatch('alert/showError', res.message);
                }
            }catch (e) {
                await this.$store.dispatch('alert/showError', e.message);
            }
        },

        /**
         * Open Dialog and set current additional details
         * @param action
         * @param cid
         */
        openDialog(action?: string, cid?: number){
            this.dialog.action = action == 'add' ? "Add" : "Update";
            this.dialog.status = true;
            this.recommendationText = "";
            this.recommendationId = null;

            if(cid){
                let selected = this.recommendations.find((o: { id: number; }) => o.id == cid)
                this.recommendationText = selected.text;
                this.recommendationId = cid;
            }
        },

        /**
         * Submit Recommendation
         */
        async submitRecommendation(): Promise <void> {
            try{
                this.loading = true
                const auth = this.$store.getters['authentication/credentials'];
                const payload = {
                    author_id: auth.user.id,
                    text: this.recommendationText,
                    id: this.recommendationId ?? null
                }
                let res = await this.$API.user().storeRecommendation(this.user.id, payload);
                if(res.success)
                {
                    if(!this.recommendationId)
                        this.recommendationText = "";
                    await this.getRecommendations()
                    this.loading = false;
                    await this.$store.dispatch('alert/showSuccess', res.message);
                }else{
                    await this.$store.dispatch('alert/showError', res.message);
                }
            }catch (e) {
                await this.$store.dispatch('alert/showError', e.message);
            }
        },

        /**
         * Delete a recommendation
         * @param id
         */
        async deleteRecommendation(id: number): Promise <void> {
            try{
                let res = await this.$API.user().deleteRecommendation(id);
                if(res.success)
                {
                    await this.getRecommendations()
                    await this.$store.dispatch('alert/showSuccess', res.message);
                }else{
                    await this.$store.dispatch('alert/showError', res.message);
                }
            }catch (e) {
                await this.$store.dispatch('alert/showError', e.message);
            }
        }
    }

});
