
import Vue from "vue";
import DisputeStatus from "@/modules/profile/components/DisputeStatus.vue";
import Recommendations from "@/modules/profile/components/Recommendations.vue";
import DocumentsSummary from "@/modules/profile/components/DocumentsSummary.vue";
import ProfileContact from "@/modules/profile/components/ProfileContact.vue";
import SocialNetworks from "@/modules/profile/components/SocialNetworks.vue";
import {hasOwnProperty} from "moment";

export default Vue.extend({
  name: "Dashboard",
  components: {
    SocialNetworks,
    ProfileContact,
    DocumentsSummary,
    DisputeStatus,
    Recommendations
  },

  props: {
    user: {
      type: Object,
      required: true
    },
    isOwner: {
      type: Boolean,
      required: false,
      default: false
    },
    creditHistory: {
      required: true,
      type: [] as Array<any>,
      default: []
    },
  },

  data: () => ({
    unreadMessagesCount: 0,
    loadInterval: null as any,
    loading: true,
  }),

  computed: {
    creditScore(){
      if(this.creditHistory.length)
        return {
          equifax: this.creditHistory[0]['equifax'] === 0 ? '---' : this.creditHistory[0]['equifax'],
          experian: this.creditHistory[0]['experian'] === 0 ? '---' : this.creditHistory[0]['experian'],
          transunion: this.creditHistory[0]['transunion'] === 0 ? '---' : this.creditHistory[0]['transunion'],
        }
      else
        return {
          equifax:  '---',
          experian:  '---',
          transunion:  '---',
        }

    },
    updatedAt(){
      if(this.creditHistory.length)
        return this.$moment(this.creditHistory[0]['created_at']).format("MMM DD, YYYY");
      else
        return this.$moment().format("MMM DD, YYYY");
    }
  },

  async created() {
    await this.loadUnreadMessages();
    this.loadInterval = setInterval(async () => {
      await this.loadUnreadMessages();
    }, 10000);
  },

  methods: {
    // Load credit score for specific user
    async loadCreditScore(): Promise <void> {
      try{
        this.loading = true;
        let res = await this.$API.user().getTheLatestCreditScore(this.user.id);
        if(res.success)
        {
          this.updatedAt = res.data.updated_at;
          delete res.data.updated_at;
          this.creditScore = res.data;
        }else{
            this.updatedAt = this.$moment().format("MMM DD, YYYY");
        }
        this.loading = false;

      }catch (e){
        await this.$store.dispatch('alert/showError', e.message);
      }
    },
    async loadUnreadMessages() {
      try {
        let res = await this.$API.messenger().getRooms();
        // console.log('res -->', res);
        if(res.length){
          this.unreadMessagesCount = res[0].unread_messages;
        }
      } catch (e){
        await this.$store.dispatch('alert/showError', e.message);
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.loadInterval);
  }
});
