
import Vue from "vue";
import moment from "moment";
import FileUploader from "@/components/form-components/FileUploader.vue";
import user from "@/store/modules/user";
import store from "@/store";

export default Vue.extend({
    name: "DocumentsSummary",
    components: {FileUploader},

    props: {
        user: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        documents: null as any,
        loading: false,
        documentsLabels: null as any,
        dialog: false as boolean,
        document: { title: "", document: null } as any,
        errorMessages: {} as any,
        isGuest: false as boolean,
    }),

    watch: {
        documents(newDocuments)
        {
            const titles = [
                'Photo ID Copy',
                'Utility Bill Copy',
                'Client Credit Report Audit',
                'Engagement and Limited Agency Agreement',
            ];

            this.documentsLabels = [];

            titles.forEach((title: any) => {
                let obj = this.documents.find((o: any) => o.title === title);

                if(obj === undefined)
                {
                    obj = {
                        uploaded: false,
                        title: title
                    }
                }else{
                    obj.uploaded = true;
                }

                this.documentsLabels.push(obj)
            })
        },
    },

    async mounted() {
        this.documents = this.user.documents;
        this.isGuestHandler();
    },

    methods: {
        /**
         * Get the document title
         */
        getTheDocumentTitle(title: string)
        {
            return title == 'Engagement and Limited Agency Agreement' ? 'Signed Client Agreement' : title;
        },
        isGuestHandler(){
          const { user } = store.getters["authentication/credentials"];
          if(user.role === "guest"){
            this.isGuest = true;
          } else {
            this.isGuest = false;
          }
        },
        /**
         * Save document
         */
        async addDocument() {
            this.loading = true;
            try {
                const response = await this.$API
                    .user()
                    .uploadUserDocuments(this.document, this.user.id);

                this.documents.push(response);
                this.dialog = false;
                this.document = { title: "", document: null };
                await this.$store.dispatch(
                    "alert/showSuccess",
                    "Document successfully uploaded"
                );
            } catch (e) {
                if (e.hasOwnProperty("errors")) {
                    this.errorMessages = e.errors;
                }
                await this.$store.dispatch("alerts/showError", e.message);
            }
            this.loading = false;
        },
    },
});
